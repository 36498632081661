import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import SubscriptionTierList from "../../components/SubscriptionTierList/SubscriptionTierList";
import LicenseForm from "../../components/LicenseForm/LicenseForm";
import LicenseList from "../../components/LicenseList/LicenseList";
import { useAuth0 } from "@auth0/auth0-react";
import { httpRegister } from "../../features/auth/registerSlice";
import reloadLocalDatabase from "../../util/reload-local-database";

const Account = ({ data }) => {
  const navigate = useNavigate();
  const disPatch = useDispatch();
  const staticStrings = useSelector(state=> state.static);
  const { isAuthenticated, logout, user } = useAuth0();

  if (!isAuthenticated) {
    <Navigate to={"/"} />;
  }

  useEffect(()=>{
      if(isAuthenticated){
        const payload = {
          firstname: user.given_name ? user.given_name : user.nickname,
          lastname: user.family_name ? user.family_name : user.nickname,
          name: user.name == user.email ? user.nickname : user.name,
          email: user.email,
          password: null
        }        
        disPatch(httpRegister(payload));
        reloadLocalDatabase({userLoggedIn: 1, name: user.name == user.email ? user.nickname : user.name, email: user.email, active: user.email_verified});
      }
  }, [isAuthenticated]);

  const steps = [
    {
      id: 1,
      title: 'Authentication',
      content: 'Create an account or login to your account in sales reporting website.',
      youtube_link: null
    },
    {
      id: 2,
      title: 'Purchase License',
      content: 'Select your preferred tier / package and purchase the license via Gumroad.',
      youtube_link: null
    },
    {
      id: 3,
      title: 'Apply License',
      content: 'Copy the license from Gumroad and Save it into your account (website) for future verification / authentication.',
      youtube_link: null
    },
    {
      id: 4,
      title: 'Important',
      content: 'Make sure you are doing this in the same browser for the extension to detect the license and allow your access in Zazzle.',
      youtube_link: null
    },
  ];
  const importantLines = [
    'Please make sure you are logged in into your Zazzle account. Without it, the plugin won\'t be able to pull out the data for report generation. ',
    'Make sure you are logging in to "Lyn Report Website" in the same browser where the plugin / extension is installed.',
    'One license can only be used by a single account. You can use that in multiple browsers or devices but only with that account ( Lyn Report ).',
    'If your user account and extension inside zazzle does not synced properly or license does not shows even if it is saved in your account or is not working as expected please "Log out" of your account from https://app.lynanalytics.com and refresh the zazzle tab if already opened. Try logging in again.'
  ];

  return (
    <>
      <Navbar data={data} />
      <div className="bg-secondary-5">
        <div className="content mx-auto place-content-center w-full pt-16 flex flex-col py-12">
          {/* w-[50rem] h-[28rem] */}
          <div className="bg-secondary-dark-royal rounded-xl mx-auto relative justify-around items-center flex flex-col p-4">
            {/* profile card */}
            <div className={`rounded-full w-24 h-24 border-2 absolute -top-10 overflow-hidden`}>
              <img src={user.picture} alt="" />
            </div>
            <div className="flex flex-col divide-y-2 divide-secondary-light/50 space-y-6 w-full px-16 mt-12">
              {/* upper top */}
              <div className="flex items-center flex-col text-white gap-4">
                <a
                  href={"/dashboard/account/edit"}
                  className="hidden text-sm font-semibold text-white"
                >
                  change profile photo
                </a>
                <div className="flex flex-col items-center">
                  <h1 className="text-3xl font-extrabold">{user.name}</h1>
                  <span>{user.email}</span>
                </div>
                <div className="flex items-center justify-around w-full text-white gap-12">
                  {/* <PasswordChangeDialog /> */}
                  <span className="text-sm font-light text-secondary-light/60 hover:text-white/80 hover:underline capitalize">
                    {/* <LoginButton message={'Logout of dashboard'}/> */}
                    <button
                      className="ml-auto flex gap-2 font-semibold"
                      onClick={() => logout()}
                    >
                      Logout of Dashboard
                    </button>
                  </span>
                </div>
              </div>
              {/* lower bottom */}
              <div className="flex items-center pt-6 space-x-20 divide-x-2 divide-secondary-light/50 place-content-center">
                {/* tier status */}
                <SubscriptionTierList data={data} />
                {/* cookie status */}
                {/* <CookieStatusComponent /> */}
              </div>
            </div>
          </div>
          {/* license start */}
          <section id="license" className="container">
            <div className="py-12 px-4 flex flex-col items-center gap-4">
              {/* license intro message */}
              <div className="flex items-baseline space-x-4">
                {/* key icon */}
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                    />
                  </svg>
                </span>
                {/* license form */}

                <div className="flex flex-col gap-2">
                  <h1 className="text-3xl font-extrabold">
                    Enter your license
                  </h1>
                  <span className="text-sm">
                    Please enter the key you obtained from Gumroad.
                  </span>
                </div>
              </div>
              {/* license display block */}
              <div className="px-8 py-6 rounded bg-secondary-dark shadow flex flex-col md:flex-row items-start justify-between gap-8">
                {/* license forms */}
                <div className="flex flex-col items-baseline text-neutral-light gap-2">
                  <h1 className="text-[1.5rem] font-extrabold">
                    Add Your License
                  </h1>
                  <p className="text-[.875rem] font-regular">
                    save the license purchased from gumroad
                  </p>

                  {/* droptdown */}
                  <LicenseForm data={data} />

                  <p className="text-[.875rem] capitalize">
                    don&apos;t have any key?
                  </p>
                  <a
                    href={staticStrings.pricing_url}
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold capitalize text-[1rem] underline hover:underline-offset-2 transition duration-100"
                  >
                    Purchase on Gumroad
                  </a>
                </div>
                {/* license status */}
                <div className="flex flex-col justify-around items-baseline gap-4">
                  <h4 className="text-[1.5rem] font-bold text-neutral-light">
                    Active Licenses
                  </h4>
                  <p className="text-[.875rem] text-neutral-light">
                    Currently purchased licenses
                  </p>
                  <LicenseList data={data} />
                </div>
              </div>
            </div>
          </section>
          {/* license ends */}
          {/* video grid start */}
          <section className="px-12 py-0">
            <h4 className="text-[1.5rem] font-bold capitalize">
              Guides to Use Sales Reporting
            </h4>
            <span className="text-sm pb-4">
              Watch an youtube video on how to get a license.
            </span>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {/* grid item */}
              {
                steps && steps.map(step=> <>
                    <div className="rounded-md p-8 text-center bg-secondary-ultralight flex flex-col justify-between items-center h-[280px]" key={step.id}>
                      <div className="flex flex-col gap-2 items-center">
                        <h1 className="text-[1.25rem] font-bold">Step #{step.id}</h1>
                        <span className="text-[1rem] font-bold">{step.title}</span>
                      </div>
                      <span className="text-[.75rem] font-light">
                        {step.content}
                      </span>
                    </div>
                </>)
              }              
            </div>
          </section>
          {/* video grid ends */}
          {/* license start */}
          <section id="license" className=" hidden px-12 py-20">
            <div className="flex items-baseline space-x-4">
              {/* key icon */}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                  />
                </svg>
              </span>
              {/* license form */}
              <div className="flex flex-col gap-2 w-full">
                <h1 className="text-3xl font-extrabold">Choose cookies</h1>
                <span className="text-sm">
                  Select which cookies are sharing
                </span>
                <div className="grid grid-cols-3 gap-4 w-full space-y-4">
                  {/* item */}
                  {/* <CookieChoiceItem /> */}
                  {/* item */}
                  {/* <CookieChoiceItem /> */}
                  {/* item */}
                  {/* <CookieChoiceItem /> */}
                  {/* item */}
                  {/* <CookieChoiceItem /> */}
                  {/* item */}
                  {/* <CookieChoiceItem /> */}
                </div>
              </div>
            </div>
          </section>
          {/* disclaimer */}
          <section id="disclaimer" className="hidden px-12 py-2">
            <div className="bg-primary-light-100 text-black rounded-lg flex flex-col items-baseline w-3/5 px-8 py-4 gap-2">
              <h1 className="text-lg font-normal">
                Cookies are essential to run the dashboard. But{" "}
                <strong className="capitalize font-bold">
                  we don&apos;t store any!
                </strong>
              </h1>
              <p className="text-[11px] text-justify">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis sint ex distinctio voluptas dolorum, temporibus
                asperiores sit repellat nemo corrupti rem commodi, aliquid, a
                doloribus quos delectus placeat tempora dolores. Lorem ipsum
                dolor sit amet, consectetur adipisicing elit. Ut recusandae,
                doloribus quos quam asperiores aspernatur aperiam ab? Voluptate
                hic at aliquam! Qui inventore, sequi explicabo exercitationem
                excepturi earum dolor provident! Lorem ipsum dolor sit, amet
                consectetur adipisicing elit. Hic repellendus odit consequuntur
                obcaecati, facilis quas sint exercitationem possimus enim
                explicabo, molestias soluta nam nihil quia doloremque sequi
                autem iusto quod?
              </p>
            </div>
          </section>
          {/* steps */}
          <section id="important" className="p-12">
              <div className="flex flex-col gap-4">
                <h1 className="text-2xl font-bold uppercase underline underline-offset-2">Important</h1>
                <ul className="flex flex-col">
                  {
                    importantLines.map((line, index) => <li key={index} className="p-2 flex gap-2 text-sm font-semibold  md:max-w-4xl">
                      <span>✅</span>
                      <span>{line}</span>
                    </li>)
                  }
                </ul>
              </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Account;
