import React, { useEffect, useState } from 'react'
import LicenseItem from '../LicenseItem/LicenseItem';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLicense, selectAllLicense } from '../../features/licenseSlice/licenseSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { FiArrowUpRight } from 'react-icons/fi';
import CancelModal from '../CancelModal/CancelModal';

const LicenseList = ({data}) => {
    const {user} = useAuth0();
    const disPatch = useDispatch();
    const licenseItems = useSelector(selectAllLicense);
    
    // const isLoading = useSelector(selectLicenseLoading);
    // const gumroadLink = useSelector(selectGumroadLink);
    const [isCancelModal, setIsCancelModal] = useState(false);

    useEffect(()=>{
        if(user){
            disPatch(fetchLicense({email: user.email}));
        }
    }, [user]);

    return (
      <div className='flex flex-col gap-4 h-full justify-between'>
          <ul className="flex flex-col gap-4">
              {/* {licenseItems.map((license)=><LicenseItem key={license.license} license={license}/>)} */}
              {licenseItems && <LicenseItem key={licenseItems.license} license={licenseItems}/>}
              {!licenseItems && <h1 className='text-2xl font-bold text-red-400 underline underline-offset-2'>No License Yet</h1>}
              {/* {!(licenseItems && licenseItems.license) && <p className='text-red-400 font-bold uppercase'>No License Saved Yet!</p>} */}
          </ul>

          {
            licenseItems && <div className="flex items-center gap-4 mt-auto">
                                <button onClick={()=>setIsCancelModal(true)} className='bg-primary-dark text-white p-2 rounded text-sm'>Request Cancellation</button>
                                <a href={'https://lynanalytics.gumroad.com/l/lyn-report-for-zazzle'} target='_blank' className='bg-primary text-secondary-dark-royal text-sm p-2 rounded flex items-center relative'>
                                    <span>Cancel Yourself</span>
                                    <span className='absolute top-0 right-0'>
                                        <FiArrowUpRight size={20}/>
                                    </span>
                                </a>
                            </div>
          }
          <CancelModal  isCancelModal={isCancelModal} setIsCancelModal={setIsCancelModal}/>
      </div>
    )
}

export default LicenseList