import React from "react";
import Travel from "../../assets/icons/undraw_departing.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorIsFormOpen,
  toggleFormOpen,
} from "../../features/survey/surveySlice";

const SurveyContainer = () => {
  const isFormOpen = useSelector(selectorIsFormOpen);
  const dispatch = useDispatch();
  const toggleForm = () => {
    dispatch(toggleFormOpen());
  };
  return (
    <div className={`hero-container ${isFormOpen && '-left-[100%] duration-300 transition transform'}`}>
      <div className="flex flex-col gap-4">
        <h1 className="text-7xl font-bold uppercase">sad to see you go!</h1>
        <p className="max-w-lg">
        We're sorry to see you go! Your feedback is invaluable to us. Please take a moment to complete our brief survey to help us improve. Your insights will make a big difference. Click here to join the survey. Thank you!
        </p>
        <div className="flex items-center gap-4">
          <button
            onClick={toggleForm}
            className="px-4 py-2 rounded text-xl uppercase bg-gradient-to-br from-primary to-primary-light font-bold hover:from-primary/80 hover:to-primary-light/80 duration-300 transition transform hover:drop-shadow-md"
          >
            Quick Survey
          </button>
          <button className="px-4 py-2 rounded text-xl uppercase bg-gradient-to-b from-secondary-dark-royal/80 to-secondary-dark-royal/60 text-white hover:from-secondary-dark-royal/80 hover:to-secondary-dark-royal/80 duration-300 transition hover:drop-shadow-md">
            Nah!
          </button>
        </div>
      </div>
      <div className="hero-banner-container">
        <img src={Travel} alt="" className="hero-image" />
      </div>
    </div>
  );
};

export default SurveyContainer;
