import React from 'react'

const SubscriptionExpiration = ({license}) => {
    
  return (
    // <span className="text-[12px] font-semibold">Expires {expiryDateStr? calculateExpiryDate(expiryDateStr): ''}</span>
    <>
      {(license && Boolean(license?.dead)) && <span className="text-[12px] font-semibold">License Expired</span>}
      <span>Charged Monthly</span>
    </>
  )
}

export default SubscriptionExpiration