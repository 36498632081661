import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {ACCESS_TOKEN, API_URL} from '../../util/static-data';

const initialState = {
    user: null,
    success: null,
    isLoading : false,
    message: null,
    validated: null,
    showPassword: false,
    showConfirmPassword: false
};

export const httpRegister = createAsyncThunk('register/fetchRegister', async(data) => {
    try{
        const {firstname, lastname, email, password, name} = data;
        const payload = {
            firstname: firstname,
            lastname: lastname,
            name: name,
            email: email,
            password: password,
            access_token: ACCESS_TOKEN
        }
        const response = await axios.post(API_URL + '/register', payload);
        return response.data;
    }catch(error){
        return error.message;
    }  
  });

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers:{
        toggleRegisterPassword: (state, action) => {
            state.showPassword = !state.showPassword
        },
        toggleRegisterConfirmPassword: (state, action) => {
            state.showConfirmPassword = !state.showConfirmPassword
        },
        validateInput: (state, action) => {
            const {name, email, password, confirmPassword} = action.payload;
            if(password !== confirmPassword){
                
                state.validated = false;
                state.message = 'Password does not match!'
            }else{
                
                state.validated = true;
                state.message = 'Validated';
            }
        },
        updateStatus: {
            reducer: (state, action) => {
                const {success, message} = action.payload;
                state.message = message || null;
                state.success = success || null;
            },
            prepare: (data) => {
                const {success, message, validated} = data;
                return {
                    payload: {
                        success, message, validated
                    }
                }
            }
        }
    },
    extraReducers(builder){
        builder
        .addCase(httpRegister.pending, (state, action)=>{
            state.isLoading = true;
          })
          .addCase(httpRegister.fulfilled, (state, action) => {
            state.isLoading = false;
            
            const {success, msg, data, status} = action.payload;
            state.success = success || false;
            state.message = msg || status || 'Try again later!';
          })
          .addCase(httpRegister.rejected, (state, action) => {
            state.isLoading = false;
          })
    }
});


export const selectRegisteredUser = (state) => state.register.user;
export const selectRegisterValidated = (state) => state.register.validated;
export const selectRegisterIsLoading = (state) => state.register.isLoading;
export const selectRegisterMessage = (state) => state.register.message;
export const selectRegisterSuccess = (state) => state.register.success;

export const registerShowPassword = (state) => state.register.showPassword;
export const registerShowConfirmPassword = (state) => state.register.showConfirmPassword;
export const {validateInput, updateStatus, toggleRegisterPassword, toggleRegisterConfirmPassword} = registerSlice.actions;
export default registerSlice.reducer