import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { saveNewLicense, selectAllLicense, selectLicenseError, selectLicenseLoading, selectLicenseMessage, toggleStatus } from '../../features/licenseSlice/licenseSlice';
import { FiLoader } from "react-icons/fi";
import { toast } from "react-toastify";
import { useAuth0 } from '@auth0/auth0-react';

const LicenseForm = ({data}) => {
    const {user} = useAuth0();
    const isLoading = useSelector(selectLicenseLoading);
    const licenseList = useSelector(selectAllLicense);
    const status = useSelector(selectLicenseError);
    const message = useSelector(selectLicenseMessage);
    const disPatch = useDispatch();    
    const [license, setLicense] = useState(); // license useState hook
    const licenseRef = useRef(); // license input element useRef hook        
    const [licenseType, setLicenseType] = useState(null);
    
    
    const error_toast_id = useRef(null);
    const success_toast_id = useRef(null);

    useEffect(()=>{
        
        if(status === true && message){
            
            if(!toast.isActive(success_toast_id.current)){
                success_toast_id.current =  toast.success(message, {
                    toastId: success_toast_id
                });
            }
            
            const payload = {
                licenseValid: 1,
                licenseValidTill: new Date().toDateString()
            };
            // disPatch(updateLicense(payload));
        } else if(status === false && message){
            if(!toast.isActive(error_toast_id.current)){
                error_toast_id.current =  toast.error(message, {
                    toastId: error_toast_id
                });
            }
            const payload = {
                licenseValid: 0,
                licenseValidTill: new Date().toDateString()
            };
            // disPatch(updateLicense(payload));
        }        
        disPatch(toggleStatus());
        
    }, [status]);
    

    function doVerification(event){
        event.preventDefault();
        const selectType = event.target[0].value;
        const licenseKey = event.target[1].value;
        const payload = {
            key: licenseKey,
            email: user.email
        }
        disPatch(saveNewLicense(payload));
    }


    
  return (
    <>
            <form className='flex flex-col space-y-4' onSubmit={(event) => doVerification(event)}>
                <select name="" id="" className="hidden input-license-select" onChange={(event) => setLicenseType(event.target.value)}>
                    <option value="">select product type</option>
                    <option className='font-bold text-[1rem] text-neutral-dark py-4' value="analytics">Lyn Report for Zazzle</option>
                    <option className='font-bold text-[1rem] text-neutral-dark py-4' value="sales">Lyn Report for Zazzle</option>
                </select>
                <div className="flex items-center shadow h-[4rem] w-[30rem] rounded">
                    <input  disabled={isLoading} ref={licenseRef} type="text" className="input-license" placeholder='xxxx-xxxx-xxxx-xxxx' onKeyUp={(event) => setLicense(event.target.value)} />
                    <button disabled={isLoading} type='submit' className="bg-primary text-black font-bold py-2 px-4 h-[3.8rem] rounded-none rounded-tr rounded-br disabled:bg-opacity-80 disabled:cursor-not-allowed" id='btnSubmit'>
                        <span id='rightArrow'>
                            {
                                !isLoading && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                                                </svg>
                            }
                            {isLoading && <FiLoader className='animate-spin transition w-6 h-6' />}
                        </span>
                        
                    </button>
                </div>
            </form>
        </>
  )
}

export default LicenseForm