import React from "react";
import { useSelector } from "react-redux";
import Logo from "../Logo/Logo";
import { selectUsefulLinks } from "../../features/footer/footerSlice";

const Footer = ({staticStrings}) => {  
  const usefulLinks = useSelector(selectUsefulLinks);
  const socialLinks = useSelector(state => state.socialLinks);
  return (
    <section className="bg-secondary-dark-royal text-neutral-light">
      <div className="container mx-auto py-12 px-4 md:px-0">
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row items-start w-full justify-between">
          {/* logo */}
          <div className="flex flex-col gap-2">
            <Logo staticStrings={staticStrings} />
            <p className="text-sm max-w-sm line-clamp-3">
              Empowering Zazzle Creators with insightful data that help you decide, determine and unlock personalized marketing strategy and more!
            </p>
          </div>
          {/* useful links */}
          <ul className="flex flex-col gap-2 items-start">
            <li>Useful Links</li>
            {usefulLinks &&
              usefulLinks.map((link) => (
                <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
                  <a href={link.link} target={link.newTab? '_blank': '_self'}>{link.title}</a>
                </li>
              ))}
          </ul>
          {/* resources */}
          <ul className="flex flex-col gap-2 items-start">
            <li>Resources</li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href="#">Lyn Analytics for Zazzle</a>
            </li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href="#">{staticStrings.title}</a>
            </li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href="#">Privacy Policy</a>
            </li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href="#">TOS</a>
            </li>
          </ul>
          {/* social links */}
          <ul className="flex flex-col gap-2 items-start">
            <li>Social Links</li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href={socialLinks.facebook}>Facebook</a>
            </li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href={socialLinks.youtube}>Youtube</a>
            </li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href={socialLinks.website}>Website</a>
            </li>
            <li className="text-sm font-light opacity-60 hover:opacity-100 transition duration-300">
              <a href={socialLinks.email}>Email</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;
