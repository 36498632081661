import { configureStore } from "@reduxjs/toolkit";
import staticStringReducers from "../features/staticTexts";
import socialLinkReducer from "../features/socialLinks";
import authReducer from '../features/auth/authSlice';
import authResponse from '../features/auth/authResponseSlice';
import licenseReducer from '../features/licenseSlice/licenseSlice';
import registerReducer from '../features/auth/registerSlice';
import loginReducer from '../features/auth/loginSlice';
import footerReducer from '../features/footer/footerSlice';
import syncReducer from '../features/databaseSyncSlice';
import surveyReducer from '../features/survey/surveySlice';

export const store = configureStore({
    reducer: {
        static: staticStringReducers,
        socialLinks: socialLinkReducer,
        auth: authReducer,
        authResponse: authResponse,
        license: licenseReducer,
        register: registerReducer,
        login: loginReducer,
        footer: footerReducer,
        sync: syncReducer,
        survey: surveyReducer
    }
})