import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    title: 'Lyn Report for Zazzle',
    version: "v1.1.2",
    version_extra: 'Beta',
    pricing_url: 'https://lynanalytics.com/#pricing',
    gumroad: '#',
    home_promo : {        
        title: 'Super Charge',
        subTitle: 'Your Zazzle Sales',
        description: 'Supercharge your Zazzle sales with lyn report for zazzle. Get sales reporting insight access to get a clear view and analytical data based on your sales report. Comprehensive graph, charts and sales widgets to help you understand your sales reports!'
    },
    emoji: {
        hi: '👋'
    },
    auth: {        
        beforeLogin: {
            title: 'Access Lyn Reports for Zazzle',
            subTitle: 'get access to your awesome zazzle sales reporting!'
        },
        afterLogin: {
            title: 'hi, good to see you!',
            subTitle: 'just one more step to your dashboard'
        }
    }
}

const staticStrings = createSlice({
    name: 'texts',
    initialState,
    reducers: {
        updateReducer : (state, action) => {
            state.version = action.payload;
        }
    }
})


export const selectAllStrings = (state) => state.texts;
export const selectGumroadLink = (state) => state.texts.gumroad;
export default staticStrings.reducer