import React from 'react'
import NavigationList from '../NavigationList/NavigationList';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const staticStrings = useSelector(state=> state.static)
  
  return (
    <section className='hidden md:block bg-secondary-dark h-full'>
        <div className='flex flex-col items-baseline py-4 space-y-12'>
          <div className="flex flex-col px-4 items-baseline leading-tight py-0">
            <h1 className="text-2xl font-extrabold px-2 text-neutral-light mb-0"><a to={"/account"}>{staticStrings.title}</a></h1>
            <div className="flex flex-row-reverse items-center justify-between text-[12px] font-bold text-white w-full px-2">
              <span className='text-[10px]'>{staticStrings.version}</span>
              <span className="rounded-xl px-3 bg-primary-light text-black">{staticStrings.version_extra}</span>
            </div>
          </div>
          {/* navigation link items as list */}
          <NavigationList />
          {/* footer component */}
          {/* <FooterDashboard /> */}
      </div>
    </section>
  )
}

export default Sidebar