import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    youtube: 'https://www.youtube.com/@LynAnalytics',
    facebook: 'https://facebook.com/lynanalytics',
    pinterest: '',
    twitter: '',
    email: 'mailto:lynanalytics@gmail.com',
    web: 'https://lynanalytics.com',
    videos: [],
    extension: '',
    gumroad: '',
    support: '',    
    featured_video: 'https://www.youtube.com/watch?v=6R1vgHSMU9g'
}

const socialLinkSlice = createSlice({
    name: 'social_links',
    initialState,
    reducers: {
        addVideo: (state, action) => {
            state.videos.push(action.payload) // adding a new video to the array
        }
    }
})

// export const {selectAllSocialLinks} = socialLinkSlice.actions;
export const selectAllSocialLinks = (state) => state.social_links;
export default socialLinkSlice.reducer;