import React from 'react'
import SubscriptionExpiration from '../SubscriptionExpiration/SubscriptionExpiration';
import { useSelector } from 'react-redux';
import { selectAllLicense, selectLicenseLoading } from '../../features/licenseSlice/licenseSlice';
import DatabaseSyncCard from '../DatabaseSyncCard/DatabaseSyncCard';
import { LuLoader } from 'react-icons/lu';

const SubscriptionTierList = ({data}) => {    
    const licenseList = useSelector(selectAllLicense);
    const isLoading = useSelector(selectLicenseLoading);    
            
    return (
        <div className='flex items-start justify-around gap-8'>
            {/* extension */}
            <div className="flex flex-col leading-tight text-white/80 items-center gap-2 relative">
                <span className="text-zinc-300/50">Extension</span>
                <span>
                    <svg width="32" height="32" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-0">
                        <path d="M28.2981 5.85653C30.5526 3.38116 34.4473 3.38116 36.7018 5.85653L39.6007 9.03974C40.7399 10.2908 42.3775 10.9691 44.068 10.8901L48.3687 10.6891C51.7129 10.5328 54.4672 13.2869 54.311 16.6313L54.1098 20.9321C54.0308 22.6223 54.7091 24.2599 55.9602 25.3992L59.1433 28.2981C61.6189 30.5526 61.6189 34.4473 59.1433 36.7018L55.9602 39.6007C54.7091 40.7399 54.0308 42.3775 54.1098 44.068L54.311 48.3687C54.4672 51.7129 51.7129 54.4672 48.3687 54.311L44.068 54.1098C42.3775 54.0308 40.7399 54.7091 39.6007 55.9602L36.7018 59.1433C34.4473 61.6189 30.5526 61.6189 28.2981 59.1433L25.3992 55.9602C24.2599 54.7091 22.6223 54.0308 20.9321 54.1098L16.6313 54.311C13.2869 54.4672 10.5328 51.7129 10.6891 48.3687L10.8901 44.068C10.9691 42.3775 10.2908 40.7399 9.03974 39.6007L5.85653 36.7018C3.38116 34.4473 3.38116 30.5526 5.85653 28.2981L9.03974 25.3992C10.2908 24.2599 10.9691 22.6223 10.8901 20.9321L10.6891 16.6313C10.5328 13.2869 13.2869 10.5328 16.6313 10.6891L20.9321 10.8901C22.6223 10.9691 24.2599 10.2908 25.3992 9.03974L28.2981 5.85653Z" fill="#FFDD2C" stroke="#232323" strokeWidth="6.92964"></path>
                        <path d="M23.9751 32.5003L29.6582 38.1834L41.0245 26.8172" fill="#FFDD2C"></path>
                        <path d="M23.9751 32.5003L29.6582 38.1834L41.0245 26.8172" stroke="#232323" strokeWidth="6.92964" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>
                <div className="flex flex-col gap-0 items-center">
                    <h4 className="text-xl font-bold">{licenseList ? (Boolean(licenseList.dead) ? 'License Expired': 'Valid License') : 'No License'}</h4>
                    <SubscriptionExpiration license={licenseList} />
                </div>
                {/* <a href={'https://lynanalytics.gumroad.com/l/znpxyh'} target='_blank'>Update License</a> */}
                <small className='text-sm'>{licenseList?.license_key}</small>
                {isLoading && <LuLoader size={40} className='animate-spin absolute inset-0 m-auto'/>}
            </div>
            {/* Dashboard */}
            <DatabaseSyncCard />
        </div>
    )
}

export default SubscriptionTierList