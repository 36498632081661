import React from 'react'

const Logo = ({staticStrings}) => {
  return (
    <div className='flex flex-col items-baseline gap-4'>        
        <div className="flex items-baseline gap-2">
          <a href={'/'}><h1 className="text-xl md:text-[3rem] font-semibold leading-none">{staticStrings.title}</h1></a> 
          <span className='text-[10px] font-extralight'>{staticStrings.version}</span>
        </div>
        <span className='text-[10px] px-2 bg-primary-light text-neutral-dark rounded-full p-auto text-left font-semibold'>{staticStrings.version_extra}</span>
    </div>
  )
}

export default Logo