import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESS_TOKEN, API_URL } from "../util/static-data";
import axios from "axios";
import reloadLocalDatabase from "../util/reload-local-database";

const initialState = {
    success: null,
    data: null,
    msg: null,
    isLoading: false
};

export const httpSyncCheck = createAsyncThunk('sync/check', async(payload) => {
    try{
        const url = API_URL + '/getCsv';
        const body = {
            email: payload.email,
            access_token: ACCESS_TOKEN
        }
        const response = await axios.post(url, body);        
        return response.data;

    }catch(error){
        return error.message;
    }
});

const databaseSyncSlice = createSlice({
    name: 'sync',
    initialState,
    reducers: { 

    },
    extraReducers(builder){
        builder
            .addCase(httpSyncCheck.pending, (state, action) => {state.isLoading = true})
            .addCase(httpSyncCheck.fulfilled, (state, action) => {
                const payload = action.payload;                
                state.isLoading = false;
                state.msg = payload.msg;
                state.data = payload.data;
                reloadLocalDatabase({databaseSynced: payload.data ? 1 : 0, syncedAt: payload?.data?.created_at, timestamp: new Date().getTime()})
            })
            .addCase(httpSyncCheck.rejected, (state, action) => {state.isLoading = false});
    }
})

export const syncLoading = (state) => state.sync.isLoading;
export const syncSuccess = (state) => state.sync.success;
export const syncMessage = (state) => state.sync.msg;
export const syncData = (state) => state.sync.data;
export default databaseSyncSlice.reducer;