import React from "react";
import { HiEye } from "react-icons/hi";
import Logo from '../Logo/Logo'

const PromoCard = ({ staticStrings, socialLinks }) => {
  return (
    <div className="p-4 sm:p-12 container bg-secondary-dark md:rounded-md text-white flex flex-col gap-4 h-screen md:h-full w-full md:w-[45rem] justify-around">
      <Logo staticStrings={staticStrings} />
      <div className="flex flex-col max-sm:-order-1 leading-tight mt-8 gap-4">
        <div className="flex flex-col gap-0">
          <h1 className="text-[4rem] font-extrabold capitalize">
            {staticStrings.home_promo.title}
          </h1>
          <h1 className="text-[2.5rem] font-semibold capitalize">
            {staticStrings.home_promo.subTitle}
          </h1>
        </div>
        <p className="text-[1rem] font-light leading-relaxed">
          {staticStrings.home_promo.description}
        </p>
      </div>
      <div className="flex flex-col gap-2 mt-8">
        <a href={socialLinks.featured_video} target="_blank" className="">
          <div className="w-1/2 md:w-[30rem] md:h-[17rem] bg-primary-light/30 shadow bg-[url('./assets/img/video-thumbnail.jpg')] bg-contain hover:shadow-md"></div>
        </a>
        <a
          href={socialLinks.youtube}
          target="_blank"
          className="login-btn text-start flex items-center gap-2 capitalize font-bold">
          <HiEye />
          watch more videos
        </a>
      </div>
    </div>
  );
};

export default PromoCard;
