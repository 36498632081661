import React, { useEffect } from 'react'
import Welcome from '../Welcome/Welcome';
import { useSelector } from 'react-redux';
import { selectAllAuthString } from '../../features/auth/authSlice';
import { useAuth0 } from '@auth0/auth0-react';
import LoginContainerAuth0 from '../LoginContainer/LoginContainerAuth0';
import reloadLocalDatabase from '../../util/reload-local-database';
import LogoutContainerAuth0 from '../LoginContainer/LogoutContainerAuth0';

const AuthContainer = () => {    
  const {isAuthenticated, user} = useAuth0();
  const auth = useSelector(selectAllAuthString);  
  useEffect(() => {
    if(isAuthenticated){
        
        reloadLocalDatabase({userLoggedIn: 1, name: user.name, email: user.email, timestamp: new Date().getTime()})
    }else{
      
      reloadLocalDatabase({userLoggedIn: 0, timestamp: new Date().getTime()})
    }
}, [isAuthenticated]);
  return (
    <div className="min-h-screen md:h-fit w-full p-4 sm:p-2 max-md:bg-secondary-ultralight grid place-content-center">
            <div className="flex flex-col gap-2 items-start justify-start">
              <Welcome auth={auth} />
              <div className='flex flex-col py-12 gap-2'>
                    {/* {errorMessage && (<span className="px-8 py-2 rounded bg-secondary-light mb-4 shadow">{errorMessage}</span>)} */}
                    {/* {auth.isLogin && (<LoginContainer />)}
                    {!auth.isLogin && (<RegisterContainer auth={auth} />)}                     */}

                    {!isAuthenticated && (<LoginContainerAuth0 />)}
                    {isAuthenticated && <LogoutContainerAuth0 />}
                </div>
            </div>
          </div> 
  )
}

export default AuthContainer