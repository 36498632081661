import React from 'react';
import { Navigate } from "react-router-dom";
import Sidebar from '../components/Sidebar/Sidebar';
import Account from '../pages/Account/Account';
// import { useDispatch } from 'react-redux';
import { useAuth0} from '@auth0/auth0-react';

const DashboardRoutes = () => {
  const {isAuthenticated} = useAuth0();
  // const disPatch = useDispatch();
  // const localData = localStorage.getItem('lynAnalyticsLoggedIn');
  // const [storage, setStorage] = useState();
  
  // if(localData){
  //   const store = JSON.parse(localData);  
  //   setStorage(store);
  //   if(!store.userLoggedIn){      
  //       <Navigate to={'/'} />
  //   }
  // }    
  
  // useEffect(()=>{
  //   disPatch(updateLoginStatus({success: null, message: null }))
  //   disPatch(init());
  // } , []);

  if(!isAuthenticated){
    return <Navigate to={'/'} />
  }

  

  return (
    <main className="flex flex-col h-screen w-full">
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <div className="flex flex-1 flex-col bg-secondary-neutral-light-5 overflow-y-auto">
          <Account />
        </div>
      </div>      
    </main>
  )
}

export default DashboardRoutes