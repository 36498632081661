import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {ACCESS_TOKEN, API_URL, API_URL_RAFIZ} from '../../util/static-data';

const initialState = {
    user: {
        userLoggedIn: null,
        id: null,
        name: null,
        email: null,
        licenseValid: null,
        licenseValidTill: null,
        databaseSynced: null,
        syncedAt: null,
    },
    success: null,
    isLoading : false,
    message: null,
    validated: null,
    showPassword: false,
    showConfirmPassword: false
};

export const httpLogin = createAsyncThunk('login/fetchLogin', async(data) => {
    try{
        const {email, password} = data;
        const payload = {
            email: email,
            password: password,
            access_token: ACCESS_TOKEN
        }
        const response = await axios.post(API_URL + '/login', payload);
        return response.data;
    }catch(error){
        return error.message;
    }  
  });

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers:{
        toggleLoginPassword: (state, action) => {
            state.showPassword = !state.showPassword
        },        
        validateLoginInput: (state, action) => {
            const {email, password} = action.payload;
            if(email && password){
                state.validated = true;
            }else{
                state.validated = false;
            }
        },
        updateLoginStatus: {
            reducer: (state, action) => {
                const {success, message} = action.payload;
                state.message = message || null;
                state.success = success || null;
            },
            prepare: (data) => {
                const {success, message, validated} = data;
                return {
                    payload: {
                        success, message, validated
                    }
                }
            }
        }
    },
    extraReducers(builder){
        builder
        .addCase(httpLogin.pending, (state, action)=>{
            state.isLoading = true;
          })
          .addCase(httpLogin.fulfilled, (state, action) => {
            state.isLoading = false;
            
            const {success, msg, data} = action.payload;
            state.success = success || false;
            state.message = msg || 'Try again later!';   
            
            if(success){
                state.user = {...data, userLoggedIn: 1};    
            }
            localStorage.setItem("lynAnalyticsLoggedIn",JSON.stringify(state.user));

          })
          .addCase(httpLogin.rejected, (state, action) => {
            state.isLoading = false;
          })
    }
})


export const selectLoginUser = (state) => state.login.user;
export const selectLoginValidated = (state) => state.login.validated;
export const selectLoginIsLoading = (state) => state.login.isLoading;
export const selectLoginMessage = (state) => state.login.message;
export const selectLoginSuccess = (state) => state.login.success;

export const loginShowPassword = (state) => state.login.showPassword;
export const {validateLoginInput, updateLoginStatus, toggleLoginPassword} = loginSlice.actions;
export default loginSlice.reducer

