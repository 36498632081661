import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { performLogOut } from '../../features/auth/authSlice';
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = ({data}) => {
const {user, logout} = useAuth0();
    const navigate = useNavigate();
  const disPatch = useDispatch();  
  // const data = JSON.parse(localStorage.getItem('lynAnalyticsLoggedIn'));

  // logout button
  const doLogout = (event) => {        
    const logOutData = {...data, userLoggedIn: 0}    
    localStorage.setItem('lynAnalyticsLoggedIn', JSON.stringify(logOutData));
    disPatch(performLogOut());
    navigate('/');
  }; 

  return (
    <div className='hidden p-6 flex items-center justify-end gap-4'>
            {/* <span className='text-5xl font-extralight'>👋</span> */}            
            <div className="rounded-full pr-6 pl-2 py-2 bg-primary flex items-center justify-between w-3/12">
                {/* logo and name*/}
                <div className="flex items-center gap-4">
                    <div className={`w-16 h-16 bg-gray-600 rounded-full overflow-hidden`}>
                        <img src={user.picture} alt="" />
                    </div>
                    <div className="flex flex-col items-baseline">
                        <h1>{user.name}</h1>
                        <span className='font-regular text-neutral-dark text-[.875rem]'>{user.email}</span>

                    </div>
                </div>
                {/* right action */}
                <button className='text-black' onClick={()=> logout()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg>
                </button>

            </div>
        </div>
  )
}

export default Navbar