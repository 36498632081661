import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: [],
  status: "idle",
  isFormOpen: false,
  error: null,
  questions: [
    {
      id: 1,
      question: "How easy was the installation for you?",
      showOptions: true,
      options: [
        "Extremely Easy",
        "Fairly Easy",
        "Easy",
        "Bad",
        "Very Bad",
        "Other",
      ],
      optionSelected: null,
      textareaVisible: false,
      otherText: null
    },
    {
      id: 2,
      question: "Rate your overall experience with the graph",
      showOptions: true,
      options: [
        "Extremely Satisfied",
        "Fairly Satisfied",
        "Satisfied",
        "Bad",
        "Very Bad",
        "Other",
      ],
      optionSelected: 0,
      textareaVisible: false,
      otherText: null
    },
    {
        id: 3,
        question: "Did you found the data useful for you?",
        showOptions: true,
        options: [
          "Extremely",
          "Very",
          "Fairly",
          "No",
          "Not At All",
          "Other",
        ],
        optionSelected: 0,
        textareaVisible: false,
        otherText: null
      },
      {
        id: 4,
        question: "Did you found Lyn Report as Trust worthy?",
        showOptions: true,
        options: [
          "Extremely",
          "Very",
          "Fairly",
          "No",
          "Not At All",
          "Other",
        ],
        optionSelected: 0,
        textareaVisible: false,
        otherText: null
      },
      {
        id: 5,
        question: "Where did you heard about us?",
        showOptions: true,
        options: [
          "Search Engine",
          "Social Media",
          "Email",
          "Youtube Videos",
          "Friends",
          "Other",
        ],
        optionSelected: 0,
        textareaVisible: false,
        otherText: null
      },
      {
        id: 6,
        question: "Why are you leaving?",
        showOptions: true,
        options: [
          "Not useful enough",
          "Too pricy",
          "Change of mind",
          "Poor Support & Feature Issues",
          "Prefer not to share",
          "Other",
        ],
        optionSelected: 0,
        textareaVisible: false,
        otherText: null
      },
      {
        id: 7,
        question: "Any suggestion for the price?",
        showOptions: true,
        options: [
          "$0 - $4.99",
          "$5 - $9.99",
          "$10 - $19.99",
          "$20 - $39.99",
          "$50>",
          "Other",
        ],
        optionSelected: 0,
        textareaVisible: false,
        otherText: null
      },
      {
        id: 8,
        question: "Feedback",
        showOptions: false,
        options: [
          
        ],
        optionSelected: 0,
        textareaVisible: true,
        otherText: null
      },
  ],
  response: null,
  handle: null
};

export const httpPluginUnInstalled = createAsyncThunk('survey/uninstall-notify', (handle) => {
  return new Promise(async (resolve, reject) => {
    try{
      const payload = {
        access_token: '37f000aa32cb05be683f06bd47a77527c868788edfb4c39a3ce5e8ee3ecba4f6',
        handle: handle
      }
      const response = await axios.post('http://api.lynanalytics.com/api/survey/uninstall', payload);
      console.log(response.data);
      resolve(response.data);
    }catch(error) {
      reject();      
    }
  });
});

export const saveSurveyHttp = createAsyncThunk('survey/uninstall', async(data) => {    
    return new Promise(async (resolve, reject) => {
        try {              
          const {handle, questions} = data;
          const payload = {
            access_token: '37f000aa32cb05be683f06bd47a77527c868788edfb4c39a3ce5e8ee3ecba4f6',
            questions: questions,
            handle: handle
          }
          const response = await axios.post('http://api.lynanalytics.com/api/survey/feedback', payload);
          console.log(response.data);
            resolve();
        }catch(error){
            reject(error);
        }
    });
});

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    toggleFormOpen: (state) => {
      state.isFormOpen = !state.isFormOpen;
    },
    toggleTextarea: (state, action) => {
        const {option, question} = action.payload;
        const singleQuestion = state.questions.find(i=> i.id == question.id);
        singleQuestion.optionSelected = option;
        if(option=='Other'){            
            singleQuestion.textareaVisible = true;            
        }else{
            // console.log('Text area is not visible but other text is: ', option);
            // singleQuestion.optionSelected = option;
            singleQuestion.textareaVisible = false;
        }
    },
    setHandle: (state,action) => {
      const {handle} = action.payload;
      console.log('Action payload: ', action.payload);
      state.handle = handle;
    },
    saveOtherText: (state, action) => {
        const {text, id} = action.payload;
        const question = state.questions.find(i=> i.id == id);
        question.otherText = text;
    },
    saveForm: (state) => {
        console.log('Form data received: ', state.questions);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveSurveyHttp.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(saveSurveyHttp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log('Form succeeded: ', action.payload);
      })
      .addCase(saveSurveyHttp.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(httpPluginUnInstalled.pending, (state, action) => {
        // state.status = 'pending';
      })
      .addCase(httpPluginUnInstalled.rejected, (state, action) => {
        // state.status = 'failed';
      })
      .addCase(httpPluginUnInstalled.fulfilled, (state, action) => {
        // state.status = 'succeeded';
        state.response = action.payload;
      });
  },
});

export const { toggleFormOpen, toggleTextarea, saveOtherText, saveForm, setHandle } = surveySlice.actions;
export const selectorIsFormOpen = (state) => state.survey.isFormOpen;
export const selectAllQuestions = (state) => state.survey.questions;
export const selectSurveyStatus = (state) => state.survey.status;
export const selectSurveyResponse = (state) => state.survey.response;
export const selectSurveyQuestionOtherTextById = (state, id) => state.survey.questions.map(i=> i.id == id)
export default surveySlice.reducer;