import React from 'react'

const SurveyThankYou = () => {
  return (
    <div className='grid place-content-center h-full w-full'>
        <div className="flex flex-col">
            <div className="flex flex-col gap-4 items-center">
                <h4 className='text-5xl font-semibold'>Thank You!</h4>
                <p className='max-w-2xl'>
                Thank you for completing the survey! We truly appreciate your feedback. If you have a moment, please consider leaving a review on Gumroad and our web store. Your reviews help us grow and serve you better. Thanks again!
                </p>
            </div>
            <div className="flex items-center justify-center gap-4 mt-6">
                <a href="https://chromewebstore.google.com/detail/lyn-report-for-zazzle/kppoohjlfeebjclffbjkmmfjghcponel" className='px-6 py-2 rounded border-2 shadow hover:shadow-md duration-300 hover:bg-green-400'>Review Web Store</a>
                <a href="https://lynanalytics.gumroad.com/l/lyn-report-for-zazzle" className='px-6 py-2 rounded border-2 shadow hover:shadow-md duration-300 hover:bg-amber-400'>Review Gumroad</a>
            </div>
        </div>
    </div>
  )
}

export default SurveyThankYou