import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SurveyContainer from '../../components/SurveyContainer/SurveyContainer'
import { httpPluginUnInstalled, selectSurveyStatus, selectorIsFormOpen, setHandle } from '../../features/survey/surveySlice'
import SurveyForm from '../../components/SurveyFrom/SurveyForm'
import SurveyThankYou from '../../components/SurveyThankYou'
import { useSearchParams } from 'react-router-dom'

const Survey = () => {
  const initialRender = useRef(true);
   const isFormOpen = useSelector(selectorIsFormOpen); 
   const status = useSelector(selectSurveyStatus);
   const dispatch = useDispatch();
   let [searchParams, setSearchParams] = useSearchParams();
   
   useEffect(()=>{    
      if(searchParams){
        if (initialRender.current) {
          initialRender.current = false;
          dispatch(setHandle({payload: searchParams.get('handle')}));
          dispatch(httpPluginUnInstalled(searchParams.get('handle')));
        }
        
      }
   }, [searchParams]);

  return (
    <div className='min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100 via-gray-200 relative'>
        <div className="container mx-auto p-12 h-[100vh] relative">
            {/* survey confirmation */}
            {!isFormOpen && <SurveyContainer />}
            {/* survey form */}
            {isFormOpen && status !== 'succeeded' && <SurveyForm />}
            {/* survey is finished */}
            {
              isFormOpen && status == 'succeeded' && <SurveyThankYou />
            }
            {
              status == 'pending' && 
              <div className="flex flex-col fixed inset-0 m-auto bg-white/80 w-fit h-fit rounded px-6 py-2 items-center">
                <span className="loading loading-dots loading-lg"></span>
                {/* <span className='text-lg tracking-wide uppercase'>Please wait...</span> */}
              </div>
            }
            
        </div>
    </div>
  )
}

export default Survey