
// get the static access token
export const ACCESS_TOKEN = '37f000aa32cb05be683f06bd47a77527c868788edfb4c39a3ce5e8ee3ecba4f6'
// static 
export const API_URL = 'http://api.lynanalytics.com/api'
export const API_URL_RAFIZ = 'http://api.lynanalytics.com/api'
// export const API_URL_RAFIZ = 'http://192.168.1.126/api'
// get the user data from the local storage
export const USER_DATA = () => {
    const temp = localStorage.getItem('lynAnalyticsLoggedIn');
    if(temp){
        const parsed = JSON.parse(temp)        
        return parsed;
    }    
    return null;    
}