import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import AuthContainer from '../../components/AuthContainer/AuthContainer';
import PromoCard from '../../components/PromoCard/PromoCard';
import { Navigate } from 'react-router-dom';
import { toggleIsLoggedIn } from '../../features/auth/authSlice';
import { useAuth0 } from '@auth0/auth0-react';
import reloadLocalDatabase from '../../util/reload-local-database';
import { FiArrowUpRight } from 'react-icons/fi';
import Footer from '../../components/Footer/Footer';
import { httpRegister } from '../../features/auth/registerSlice';
import { fetchLicense } from '../../features/licenseSlice/licenseSlice';

const Home = () => {
  const {isAuthenticated, user} = useAuth0();
  const staticStrings = useSelector(state=> state.static)
  const socialLinks = useSelector(state => state.socialLinks);  
  const dispatch = useDispatch();  
  
  if (isAuthenticated) {    
    <Navigate to={'/account'} />    
  }

  useEffect(()=>{
    if(isAuthenticated){
      dispatch(toggleIsLoggedIn());
      reloadLocalDatabase({userLoggedIn: 1, name: user.name == user.email ? user.nickname : user.name, email: user.email, active: user.email_verified});
      const payload = {
        firstname: user.given_name ? user.given_name : user.nickname,
        lastname: user.family_name ? user.family_name : user.nickname,
        name: user.name == user.email ? user.nickname : user.name,
        email: user.email,
        password: null
      }
      dispatch(httpRegister(payload));
      dispatch(fetchLicense({email: user.email}));
    }else{
      reloadLocalDatabase({userLoggedIn: 0, timestamp: new Date().getTime()})
    }
  }, [isAuthenticated]);

  return (
    <main className="min-h-screen flex flex-col">
        {/* home page login container */}
        <section className="auth-banner sm:bg-secondary-ultralight w-full min-h-screen">
          <div className="container mx-auto flex flex-col items-center md:items-start md:flex-row justify-center md:gap-8 md:p-12">
            <PromoCard staticStrings={staticStrings} socialLinks={socialLinks} />
            <AuthContainer />
          </div>
        </section>
        {/* pricing container */}
        <section className="hidden pricing bg-secondary-neutral-light-5 w-full py-12">
          <div className="grid place-content-center px-12 container mx-auto">
            <div className="flex flex-col items-center gap-4">
              <h1 className='font-extrabold text-[2.5rem] capitalize'>pricing plans</h1>
              <p className='text-[1rem] font-light px-4 text-center w-[26rem] h-[3rem]'>Our pricing plans are designed to be affordable, flexible and tailored to your unique needs.</p>
              <a href={staticStrings.pricing_url} target='_blank' className='rounded shadow h-[3rem] w-[14rem] bg-secondary-neutral-5 text-primary-light-100 font-bold capitalize flex items-center justify-center relative'>
                  <span>View Pricing Plans</span>
                  <span className='absolute top-0 right-0'>
                    <FiArrowUpRight size={20}/>
                  </span>
              </a>
            </div>

            {/* pricing grid */}
            <div className="grid grid-cols-2 gap-4 py-12">
              {/* free trial */}
              <div className="hidden col-span-2 h-[25rem] w-[69rem] bg-neutral-light rounded shadow flex flex-col items-center justify-evenly gap-0">
                <div className="flex items-center gap-4">
                  {/* image here */}
                  <img  src={'/img/icons/cycle.png'} height={50} width={50} alt=''/>
                  <div className="flex flex-col leading-tight">
                    <span className="text-[1.5rem] font-bold">Free tier</span>
                    <span><span className='text-[2.25rem] font-extrabold'>$0</span>/mo</span>
                  </div>
                </div>
                <div className="flex flex-col items-center space-y-8 border-t-2 border-black pt-4 w-4/5">
                  <ul className="flex flex-col list-disc">
                    <li className="text-[1rem]">Very limited features of PRO Tier</li>
                    <li className="text-[1rem]">No Data filtering</li>
                    <li className="text-[1rem]">Restricted Dashboard Features</li>
                  </ul>
                  <div className="flex flex-col items-center">
                    <button className='rounded shadow h-[3rem] w-[14rem] bg-secondary-neutral-5 text-primary-light-100 font-bold capitalize'>install extensions</button>
                    <p className="text-[.75rem] capitalize">learn more about features</p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 justify-center w-full px-4">
              <div className="bg-primary-light-100 h-[11rem] rounded flex flex-col items-baseline justify-center px-8">
                    <h4 className='text-[1.5rem] font-bold'>Looking for an offer or Affiliate Program?</h4>
                    <p className='text-[1rem]'>Get in touch with our sales team. If any offer going on we will send you a coupon to use at checkout. If Email us <a href='mailto:mail@lynanalytics.com'>mail@lynanalytics.com</a></p>
              </div>
              <div className="h-[11rem] bg-neutral-light rounded flex flex-col items-baseline justify-center px-8">
                <h4 className="font-bold text-[1.5rem]">Facing issue?</h4>
                <p className='text-[1rem]'>Send Message to Developer Team. We will get back as soon as we can. Email <a href='mailto:mail@lynanalytics.com'>mail@lynanalytics.com</a></p>
              </div>
          </div>
          
        </section>
        {/* pricing table */}
        {/* footer */}
        <Footer staticStrings={staticStrings} /> 
      </main>
  )
}

export default Home