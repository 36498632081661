import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FiLoader } from 'react-icons/fi';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { getLicenseCancelMessage, getLicenseCancelStatus, httpLicenseCancel } from '../../features/licenseCancelSlice';

const CancelModal = ({isCancelModal=true, setIsCancelModal}) => {

    const {user, isAuthenticated, isLoading} = useAuth0();
    const disPatch = useDispatch();    
    const status = useSelector(getLicenseCancelStatus);
    const message = useSelector(getLicenseCancelMessage);

    const success_toast_id = useRef(null);
    const error_toast_id = useRef(null);
    

    const sendLicenseCancelRequest = (event) => {
        event.preventDefault();
        
        const reason = event.target[0].value;
        const payload = {
            reason: reason,
            email: user.email
        }
        disPatch(httpLicenseCancel(payload));        
    };

    useEffect(()=>{
        // check if status is true or status is false 
        if(status === 'succeeded'){
            if(! toast.isActive(success_toast_id.current)){
                success_toast_id.current =  toast.success(message, {
                    toastId: success_toast_id
                });
            }            
           }else if (status === 'failed') {
            if(! toast.isActive(error_toast_id.current)){
                error_toast_id.current =  toast.error(message, {
                    toastId: error_toast_id
                });
            }
           }         
    }, [status]);


  return (
    <div className={`fixed inset-0 m-auto bg-secondary-ultralight p-4 h-fit w-fit shadow-md rounded drop-shadow-md ${isCancelModal ? 'scale-1' : 'scale-0'} duration-300`}>
        {status==='loading' && <FiLoader size={120} className='animate-spin absolute inset-0 m-auto'/>}
        <div className='flex flex-col items-start justify-between h-full'>
            <h1 className='text-lg font-semibold'>Request License Cancellation</h1>
            <p className='text-sm max-w-xl'>By clicking this confirm button you are sending us a <span className='font-semibold'>cancellation request</span> to cancel your subscription to <span className="text-sm font-semibold">Lyn Report for Zazzle</span>.</p>
            <form action="" className='flex flex-col py-6 w-full gap-2' onSubmit={sendLicenseCancelRequest}>
                <label htmlFor="" className='text-base font-semibold'>Why did you choose to cancel?</label>
                <textarea name="" id="" className='w-full min-h-32 border-0 ring-0 rounded shadow focus:ring-0 active:ring-0 placeholder:text-gray-300' placeholder='Tell us about your cancellation please.'></textarea>
                {/* action buttons for the form */}
                <div className="flex items-center gap-2 mt-auto justify-end w-full">
                    <button disabled={!user} className='px-3 py-1 rounded text-white bg-primary-dark'>Confirm</button>
                    <button type='button' className='underline underline-offset-4' onClick={()=> setIsCancelModal(false)}>Nah</button>
                </div>
            </form>            
        </div>
        
    </div>
  )
}

export default CancelModal