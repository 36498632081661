import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import reloadLocalDatabase from '../../util/reload-local-database';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LogoutContainerAuth0 = () => {
    const {isAuthenticated, loginWithRedirect, logout} = useAuth0();
    const staticStrings = useSelector(state => state.static);

    useEffect(() => {
        if(!isAuthenticated){            
            reloadLocalDatabase({userLoggedIn: 0, timestamp: new Date().getTime()})
        }
    }, [isAuthenticated]);

  return (
    <div className='flex flex-col items-start gap-4'>
        <p><span className="font-semibold">{staticStrings.title}</span> offering an exciting way to view your sales data in a meaningful way. This helps you get a clear overview of how well your store is performing and what your next movement should be in order to make more sales. Turn your boring CSV into beautiful charts, graphs and something meaningful for humans to understand and decide. </p>   
        <p>Top researchers from <span className="font-semibold">{staticStrings.title}</span> are working to provide useful and meaningful insights for your better understanding of your store's performance.</p>
        <div className="flex items-center gap-4">
            <button onClick={()=> logout()} className='bg-primary px-6 py-2 rounded font-semibold uppercase'>Log Out</button>
            
            <Link to={'/account'} className='bg-secondary px-6 py-2 rounded font-semibold text-white uppercase'>My Account</Link>
        </div>
    </div>
  )
}

export default LogoutContainerAuth0