import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCESS_TOKEN, API_URL } from "../../util/static-data";
import reloadLocalDatabase from "../../util/reload-local-database";

const initialState = {
    licenses: null,
    sales: [],
    isLoading: false, 
    success: null,   
    message: null
};

export const fetchLicense = createAsyncThunk('license/fetchSingle', async(payload) => {
    
    try{
        const url = API_URL + '/getLicense';
        const data = {
            'access_token': ACCESS_TOKEN,
            'email': payload.email
        };
        const response = await axios.post(url , data);        
        return response.data;
    }catch(error){
        return error.message;
    }
});

export const saveNewLicense = createAsyncThunk('license/saveNew', async(payload)=>{
    try{        
        const url = API_URL + '/license';
        const data = {
            'access_token': ACCESS_TOKEN,
            'key': payload.key, // EFE51F18-121141A6-93183C90-91B67262
            'email': payload.email
        };        
        const response = await axios.post(url, data);
        return response.data;
    }catch(error){
        return error.message;
    } 
});

const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {
        toggleStatus: (state) => {
            state.success = null;
            state.message = null;
            state.isLoading = false;
        }
     },
    extraReducers (builder) {
        builder
            .addCase(saveNewLicense.pending, (state, actions) => {                
                state.isLoading = true;
            })
            .addCase(saveNewLicense.fulfilled, (state, actions)=> {
                const {success, msg, data} = actions.payload;
                state.success = success;
                // state.message = msg  || (success===true?'License verified successfully':'Failed to verify License'); 
                state.message = msg; 
                state.isLoading = false;
                state.sales = data?.sales || [];
                if(success){                    
                    // state.licenses  = temp;                    
                    state.licenses = {...data.license, ...data.sales};
                    reloadLocalDatabase({licenseValid: Boolean(data.sales.dead) ? 0 : 1, licenseValidTill: Boolean(data.sales.dead) ? data.license.subscription_cancelled_at : null, timestamp: new Date().getTime()})
                }              
            })
            .addCase(saveNewLicense.rejected, (state, actions) => {
                state.message = 'Server connect Failed.'
                state.success = false;
                state.isLoading = false;
            })
            .addCase(fetchLicense.fulfilled, (state, actions) => {                    
                const {success, msg, data} = actions.payload;
                state.success = success;
                state.message = msg;
                if(success){                    
                    state.licenses = data;
                    reloadLocalDatabase({licenseValid: Boolean(data.dead) ? 0 : 1, licenseValidTill: Boolean(data.dead) ? data.subscription_cancelled_at : null, timestamp: new Date().getTime()})
                }else{
                    state.licenses = null;                    
                    reloadLocalDatabase({licenseValid: 0, timestamp: new Date().getTime()})
                }
                
            })
            .addCase(fetchLicense.pending, (state, actions) => {
                state.isLoading = true;
            })
            .addCase(fetchLicense.rejected, (state, actions) => {
                state.isLoading = false;                
            });
            // .addCase(httpLicenseCancel.pending, (state, actions) => {
            //     state.isLoading = true;                
            // })
            // .addCase(httpLicenseCancel.fulfilled, (state, actions) => {
            //     state.isLoading = false;
            //     // httpLicenseCancel
            //     const {success, data, msg} = actions.payload;
            //     if(success){                    
            //         // state.licenses = null;
            //         // reloadLocalDatabase({licenseValid: 0, licenseValidTill:  null})
            //         state.message = 'Cancellation request has been sent';
            //         state.success = true;
            //     }else{
            //         state.message = 'Failed to send cancellation request!';
            //         state.success = false;
            //         // state.licenses = null;                    
            //         // reloadLocalDatabase({licenseValid: 0, licenseValidTill: null})
            //     }
            // })
            // .addCase(httpLicenseCancel.rejected, (state, actions) => {
            //     state.isLoading = true;
            // });
    }
})


export const selectAllLicense = (state) => state.license.licenses;
export const selectLicenseError = (state) => state.license.success;
export const selectLicenseMessage = (state) => state.license.message;
export const selectLicenseLoading = (state) => state.license.isLoading;
export const {toggleStatus} = licenseSlice.actions
export default licenseSlice.reducer