import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESS_TOKEN, API_URL } from "../util/static-data";
import axios from "axios";

// cancel license
export const httpLicenseCancel = createAsyncThunk('license/cancel', async(payload) => {
    try{
        const url = API_URL + '/cancelLicense';
        const data = {
            email: payload.email,
            reason: payload.reason,
            'access_token': ACCESS_TOKEN,
        };
        
        const response = await axios.post(url, data);        
        return response.data;
    }catch(error){
        // cancel the license here
        return error.message
    }
});

const initialState = {
    status: 'idle',
    error: null,
    message: '',
    data: []
}

const licenseCancelSlice = createSlice({
    name: 'cancelLicense',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(httpLicenseCancel.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(httpLicenseCancel.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = 'Cancellation request sent.';
                const {success, msg, data} = action.payload;
            })
            .addCase(httpLicenseCancel.rejected, (state, action) => {
                state.status = 'failed';
                state.message = 'Failed to send cancellation request.'
            });
    }
})

export const getLicenseCancelStatus = (state) => state.status;
export const getLicenseCancelError = (state) => state.error;
export const getLicenseCancelMessage = (state) => state.message;
export default licenseCancelSlice.reducer;