import React from 'react'

const LicenseItem = ({key, license}) => {
  return (
    <div className="rounded-md shadow bg-primary-light-100 px-8 py-3 flex flex-col items-start relative w-full h-[6rem] justify-around">
        <h2 className="font-bold text-[1rem]">{license?.message}</h2>
        <span className='text-[.875rem]'>{license.license_key}</span>
        {license.subscription_cancelled_at && <span className='text-[.875rem]'>Cancelled at <strong>{license.subscription_cancelled_at}</strong></span>}
        { license.subscription_ended_at && <span className='text-[.875rem]'>Ended at <strong>{license.subscription_ended_at}</strong></span> }
        { license.subscription_failed_at && <span className='text-[.875rem]'>Failed at <strong>{license.subscription_failed_at}</strong></span>}
    </div>
  )
}

export default LicenseItem