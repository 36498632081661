import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import reloadLocalDatabase from '../../util/reload-local-database';
import { useDispatch, useSelector } from 'react-redux';
import { LuLoader } from 'react-icons/lu';

const LoginContainerAuth0 = () => {
    const staticString = useSelector(state => state.static);
    const {isLoading, isAuthenticated, loginWithRedirect} = useAuth0();
    const disPatch = useDispatch();

    useEffect(() => {
        if(isAuthenticated){            
            reloadLocalDatabase({userLoggedIn: 1, timestamp: new Date().getTime()})
        }
    }, [isAuthenticated]);    

  return (
    <div className='flex flex-col items-start gap-4'>
        <p><span className='font-semibold'>{staticString.title}</span> offering an exciting way to view your sales data in a meaningful way. This helps you get a clear overview of how well your store is performing and what your next movement should be in order to make more sales. Turn your boring CSV into beautiful charts, graphs and something meaningful for humans to understand and decide. </p>   
        <p>Top researchers from <span className='font-semibold'>{staticString.title}</span> are working to provide useful and meaningful insights for your better understanding of your store's performance.</p>
        <div className="flex items-center gap-4">
            <button onClick={()=> loginWithRedirect()} className='bg-primary px-6 py-2 rounded font-semibold uppercase relative'>
                <span>Login to get Access</span>
                {isLoading && <LuLoader size={40}  className='absolute inset-0 m-auto animate-spin'/>}
            </button>
            <a href={staticString.pricing_url} target='_blank' className='bg-secondary px-6 py-2 rounded font-semibold text-white uppercase'>view pricing</a>
            
        </div>
    </div>
  )
}

export default LoginContainerAuth0