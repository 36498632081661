/* eslint-disable no-unused-expressions */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {API_URL, ACCESS_TOKEN} from '../../util/static-data'

const initialState = {
  title: "Hello, Guest !",
  subTitle: "login to lyn report for zazzle and get access to sales reporting.",
  isLogin: true,
  isLoggedIn: false,
  user: null,
  isLoading: false,
  token: "",
  success: null,
  error: null,
  message: null,
  login: {
    email: null,
    password: null,
    showPassword: false,
    showConfirmPassword: false,
    error: null,
    loggedIn: false,
  },
  register: {
    full_name: null,
    email: null,
    password: null,
    confirmPassword: null,
    error: null,
    showPassword: false,
    showConfirmPassword: false,
  },
};

export const fetchAuthenticateUser = createAsyncThunk(
  "auth/login",
  async (data, action) => {
    try {
      // const url = "http://192.168.1.126/api/login";
      const url = API_URL + "/login";
      const response = await axios.post(url, {
        email: data.email,
        password: data.password,
        access_token: ACCESS_TOKEN,
      });

      return response.data.data;
    } catch (error) {
      return null;
    }
  }
);

const authSlicer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleStatusMessage: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;
    },
    toggleIsLogin: (state) => {
      state.isLogin = !state.isLogin;
      if (!state.isLoggedIn && state.isLogin) {
        state.title = "Login to get access";
        state.subTitle =
          "login to lyn report for zazzle and get access to sales reporting.";
      }

      if (!state.isLoggedIn && !state.isLogin) {
        state.title = "Create an account";
        state.subTitle = "create an account and get access to sales reporting.";
      }
    },
    toggleIsLoggedIn: (state) => {
      state.isLoggedIn = 1;
    },
    toggleShowPassword: (state) => {
      if (state.isLogin) {
        state.login.showPassword = !state.login.showPassword;
      } else {
        state.register.showPassword = !state.register.showPassword;
        // state.register.showConfirmPassword = !state.register.showConfirmPassword;
      }
    },
    toggleShowConfirmPassword: (state) => {
      state.register.showConfirmPassword = !state.register.showConfirmPassword;
    },
    toggleShowLoginPassword: (state) => {
      state.login.showPassword = !state.login.showPassword;
    },
    setLogin: (state, action) => {
      state.login.email = action.payload.email;
      state.login.password = action.payload.password;
      // state.login.confirmPassword = action.payload.confirmPassword;
      // state.login.error =
      //   action.payload.password != action.payload.confirmPassword
      //     ? "Password does not match!"
      //     : null;
    },
    setRegister: (state, action) => {
      state.register = action.payload;
      state.register.error =
        state.register.password != state.register.confirmPassword
          ? "Password does not match"
          : null;
    },
    performLogin: (state) => {
      if (
        state.login.email == "ashik@gmail.com" &&
        state.login.password == "12345"
      ) {
        state.isLoggedIn = true;
        (state.title = "Welcome back John 1-1-7"),
          (state.subTitle =
            "You will be redirected back to your account page shortly. Or click the link below...");
        state.isLoggedIn = true;
        state.login.loggedIn = true;
      } else {
        state.title = "Login to get access";
        state.subTitle =
          "login to lyn report for zazzle and get access to sales reporting.";
        state.isLoggedIn = false;
      }
    },
    performLogOut: (state) => {
      state.title = "Login to get access";
      state.subTitle =
        "login to lyn report for zazzle and get access to sales reporting.";
      state.isLoggedIn = false;
      state.login.loggedIn = false;

      const authResponse = {
        userLoggedIn: 0,
        id: null,
        name: null,
        email: "",
        licenseValid: 0,
        licenseValidTill: null,
        databaseSynced: 0,
        syncedAt: null,
      };
      localStorage.setItem(
        "lynAnalyticsLoggedIn",
        JSON.stringify(authResponse)
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAuthenticateUser.fulfilled, (state, actions) => {
        state.isLoading = false;
        if (actions.payload) {
          
          state.user = actions.payload;
          const {id, name, firstname, lastname, email, active, syncedAt, licenseValidTill, licenseValid, databaseSynced}  = actions.payload;
          state.isLoggedIn = true;
          state.success = "Login success";
          const authResponse = {
            userLoggedIn: 1,
            id: id,
            name: name,
            email: email,
            licenseValid: licenseValid,
            licenseValidTill: licenseValidTill,
            databaseSynced: databaseSynced,
            syncedAt: syncedAt,
          };
          localStorage.setItem(
            "lynAnalyticsLoggedIn",
            JSON.stringify(authResponse)
          );
        } else {
          state.user = null;
          state.isLoggedIn = false;
          state.error = "Login failure!";
          const authResponse = {
            userLoggedIn: 0,
            id: null,
            name: null,
            email: "",
            licenseValid: 0,
            licenseValidTill: null,
            databaseSynced: 0,
            syncedAt: null,
          };
          localStorage.setItem(
            "lynAnalyticsLoggedIn",
            JSON.stringify(authResponse)
          );
        }
        // state.isLoading = false;
      })
      .addCase(fetchAuthenticateUser.pending, (state, actions) => {
        state.isLoading = true;
        state.user = null;
        // state.error = 'Login failure!'
        const authResponse = {
          userLoggedIn: 0,
          id: null,
          name: null,
          email: "",
          licenseValid: 0,
          licenseValidTill: null,
          databaseSynced: 0,
          syncedAt: null,
        };
        localStorage.setItem(
          "lynAnalyticsLoggedIn",
          JSON.stringify(authResponse)
        );
      })
      .addCase(fetchAuthenticateUser.rejected, (state, actions) => {
        state.isLoading = false;
        state.user = null;
        state.error = "Login failure!";
        const authResponse = {
          userLoggedIn: 0,
          id: null,
          name: null,
          email: "",
          licenseValid: 0,
          licenseValidTill: null,
          databaseSynced: 0,
          syncedAt: null,
        };
        localStorage.setItem(
          "lynAnalyticsLoggedIn",
          JSON.stringify(authResponse)
        );
      });
  },
});

export const selectAllAuthString = (state) => state.auth;
// export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectSuccess = (state) => state.auth.success;
export const selectError = (state) => state.auth.error;
export const {
  toggleStatusMessage, // toggle the status success and error message after showing the toast message
  toggleIsLoggedIn, // toggle is logged in
  toggleIsLogin,
  toggleShowPassword,
  toggleShowConfirmPassword,
  setLogin,
  setRegister,
  performLogin,
  toggleShowLoginPassword,
  performLogOut,
} = authSlicer.actions;
export default authSlicer.reducer;
