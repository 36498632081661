import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    usefulLinks: [
        {id: 1, title: 'Home', link: '/', newTab: false},
        {id: 2, title: 'Pricing', link: 'https://lynanalytics.com/#pricing', newTab: true},
        {id: 3, title: 'Blog', link: 'https://lynanalytics.com/blog', newTab: true},
        {id: 4, title: 'Features', link: 'https://lynanalytics.com/#features', newTab: true},
    ],
    resources: [
        {id: 1, title: 'Analytics Tool', link: 'https://chromewebstore.google.com/detail/lyn-analytics-for-zazzle/ianhafdedjkpoagaalhdlenffgcncgpj', newTab: true},
        {id: 2, title: 'Sales Reporting', link: 'https://lynanalytics.com/#pricing', newTab: true},
        {id: 3, title: 'Privacy Policy', link: 'https://lynanalytics.com/privacy-policy/', newTab: true},
        {id: 4, title: 'Terms of Services', link: 'https://lynanalytics.com/terms-and-services/', newTab: true},
    ],
    socials: [
        {id: 1, title: 'Facebook', link: 'https://www.facebook.com/LynAnalytics', newTab: true, icon: ''},
        {id: 1, title: 'YouTube', link: 'https://www.youtube.com/@LynAnalytics', newTab: true, icon: ''},
        // {id: 1, title: 'Twitter', link: '', newTab: true, icon: ''},
        {id: 1, title: 'Web', link: 'https://lynanalytics.com', newTab: true, icon: ''},
        {id: 1, title: 'Email', link: 'mailto:lynanalytics@gmail.com', newTab: true, icon: ''},
    ],
    address: ''
};


const footerSlice = createSlice({
    name: 'footer',
    initialState,
    reducers:{

    }
})

export const selectUsefulLinks = (state) => state.footer.usefulLinks;
export const selectResourceLinks = (state) => state.footer.resources;
export default footerSlice.reducer