import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userLoggedIn: 0,
  id: null,
  name: null,
  email: null,
  license: null,
  licenseValid: 0,
  licenseValidTill: null,
  databaseSynced: 0,
  syncedAt: null,  
};

const authResponseSlice = createSlice({
  name: "authResponse",
  initialState,
  reducers: {
    init: (state, action) => {
      const localData = localStorage.getItem('lynAnalyticsLoggedIn');
      if(localData){
        const {userLoggedIn, id, userName, email, licenseValid, licenseValidTill, databaseSynced, syncedAt } = JSON.parse(localData);
        state.userLoggedIn = userLoggedIn;
        state.id = id;
        state.email = email;
        state.licenseValid = licenseValid;
        state.licenseValidTill = licenseValidTill;
        state.name = userName;
        state.syncedAt = syncedAt;
        state.databaseSynced = databaseSynced;
      }else{
        updateResponse({});
      }
    },
    updateResponse: {
      reducer: (state, action) => {
        state = action.payload;
      },
      prepare: (
        data
      ) => {
        const {userLoggedIn, id, userName, email, licenseValid, licenseValidTill, databaseSynced, syncedAt } = data;
        return {
          payload: {
            userLoggedIn: userLoggedIn,
            id: id,
            name: userName,
            email: email,
            licenseValid: licenseValid,
            licenseValidTill: licenseValidTill,
            databaseSynced: databaseSynced,
            syncedAt: syncedAt,
          },
        };
      },
    },
    updateLicense: {
      reducer: (state, action) => {
        const {licenseValid, licenseValidTill} = action.payload;
        state.licenseValid = licenseValid;
        state.licenseValidTill = licenseValidTill;
        const rawLicense = localStorage.getItem('lynAnalyticsLoggedIn') ?  JSON.parse(localStorage.getItem('lynAnalyticsLoggedIn')) : {};
        const newLicense = {...rawLicense, licenseValid: licenseValid, licenseValidTill: licenseValidTill };        
        localStorage.setItem('lynAnalyticsLoggedIn', JSON.stringify(newLicense));

      },
      prepare: (data) => {
        return {
          payload: data
        }
      }
    }
  },
});

export const selectAuthResponse = (state) => state.authResponse;
export const selectIsLoggedIn = (state) => state.authResponse.userLoggedIn;
export const {updateResponse, updateLicense, init} = authResponseSlice.actions;
export default authResponseSlice.reducer;
