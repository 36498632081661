import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';

const Welcome = ({auth}) => {

  const {isAuthenticated, user} = useAuth0();  
  
  return (
    <div className='flex items-center gap-1'>
        <span className='text-4xl font-extralight'>👋</span>
        <div className="flex flex-col leading-relaxed">
          {
            !isAuthenticated && <>
              <h4 className="text-4xl font-extrabold capitalize">{auth.title}</h4>
              <p className="max-w-xl font-light capitalize">{auth.subTitle}</p>
            </>
          }

          {
            isAuthenticated && <>
              <h4 className="text-4xl font-extrabold capitalize">Welcome back, {user.name}</h4>
              <p className="max-w-xl font-semibold capitalize">Access to your <a href="/account">Account</a> to view/manage your license.</p>
            </>
          }
            
        </div>
    </div>
  )
}

export default Welcome