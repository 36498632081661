import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import {store} from '../src/app/store'
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth0Provider } from '@auth0/auth0-react';

const AUTH0_NAME=`Lyn Report`
const AUTH0_DOMAIN=`lynanalytics.us.auth0.com`
const AUTH0_CLIENT_ID=`bJyagDgl1nFnFH2FkY2QQoRAsThkL26k`
const AUTH0_CLIENT_SECRET=`LyZr0gQEsloQHw6MPnBGW8HHtFCu6MyTfeCTzMq7N9v1GBm77qwHIdFJzTGzUbHd`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);