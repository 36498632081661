import Home from './pages/Home/Home';
import { Routes, Route } from 'react-router-dom';
import DashboardRoutes from './routes/DashboardRoutes';
import Survey from './pages/Survey/Survey';

// {
//   userLoggedIn : 1
//   name: 'John Doe'
//   licenseValid: 0
//   licenseValidTill: new Date()
//   databaseSynched: 1
//   syncedAt: new Date()
// }

function App() {  
  return (    
    <>
      <Routes>        
        <Route path='/' element={<Home />} />
        <Route path='/account' element={<DashboardRoutes />} />
        <Route path='/survey' element={<Survey />} />
      </Routes>
    </>
  );
}

export default App;
