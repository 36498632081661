import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveOtherText,
  saveSurveyHttp,
  selectAllQuestions,
  selectorIsFormOpen,
  toggleFormOpen,
  toggleTextarea,
} from "../../features/survey/surveySlice";
import { useSearchParams } from "react-router-dom";

const SurveyForm = () => {
  const initialRender = useRef(true);
  const isFormOpen = useSelector(selectorIsFormOpen);
  const questions = useSelector(selectAllQuestions);
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [price, setPrice] = useState(9.99);

  const toggleForm = () => {
    dispatch(toggleFormOpen());
  };

  const updatePrice = (event) => {
    const suggestedPrice = event.target.value;
    setPrice(suggestedPrice);
  };

  const optionSelected = (event, question) => {
    event.preventDefault();

    const payload = {
      option: event.target.value,
      question: question,
    };

    dispatch(toggleTextarea(payload));
  };

  const saveFormLocal = (event) => {
    event.preventDefault();
    dispatch(saveSurveyHttp({questions, handle: searchParams.get('handle')}));
    console.log(questions);
  };

  const surveyItemChecked = (question) => {
    console.log(question);
  };

  const onTextareaHandler = (event, id) => {
    const text = event.target.value;
    const payload = { text, id };
    dispatch(saveOtherText(payload));
  };

  return (
    <div
      className={`font-inter absolute inset-0 m-auto flex justify-start items-start gap-4 h-fit ${
        !isFormOpen &&
        "-left-[100%] duration-300 transition transform font-mono"
      }`}
    >
      <div className="container mx-auto p-4">
        <div className="flex flex-col py-12">
          <div className="flex items-center gap-4">
            <button
              onClick={toggleForm}
              className="bg-secondary-dark-royal text-white font-semibold uppercase text-sm px-3 py-1 rounded"
            >
              Go Back
            </button>
            <h1 className="font-bold text-3xl">
              Please follow the questions and let us know your feedback.
            </h1>
          </div>
          <form
            action="#"
            onSubmit={saveFormLocal}
            className="grid grid-cols-2 gap-4 mt-12"
          >
            {questions.map((question, index) => {
              return (
                <div
                  className="flex flex-col gap-2"
                  key={index}
                  onChange={() => surveyItemChecked(question)}
                >
                  <label className="text-2xl" htmlFor="install">
                    {question.question}
                  </label>
                  {question.showOptions == true && (
                    <select
                      class="select select-primary w-full max-w-xs text-neutral-50"
                      onChange={(event) => optionSelected(event, question)}
                      id="installSelect"
                    >
                      <option disabled selected>
                        Rate your experience with us.
                      </option>
                      {question.options.map((option, j) => (
                        <option value={option} key={j}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                  <textarea
                    onChange={(event) => onTextareaHandler(event, question.id)}
                    value={question.otherText}
                    id="installText"
                    placeholder="Share your thoughts.."
                    class={`${
                      question.textareaVisible == true ? "" : "hidden"
                    } textarea textarea-bordered textarea-sm w-full max-w-xs text-neutral-50`}
                  ></textarea>
                </div>
              );
            })}
            <div className="flex items-center gap-4 mt-8 w-full">
              <button className="px-4 py-2 rounded bg-green-500 hover:bg-green-600 duration-200 font-bold text-white">
                Share Feedback
              </button>
              {/* <button className='px-4 py-2 rounded bg-amber-500 hover:bg-amber-600 duration-200 font-bold text-white'>Leave It</button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SurveyForm;
