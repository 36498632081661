export default function reloadLocalDatabase(payload){
    let obj = {
        active: 0,
        email: null,
        firstname: null,
        id: null,
        lastname: null,
        licenseValid: null,
        licenseValidTill: null,
        name: null,
        userLoggedIn:  0,
        databaseSynced: 0,
        syncedAt: null
    }
    const localdata = localStorage.getItem('lynAnalyticsLoggedIn');

    if(localdata){
        const {active, email, firstname, lastname, id, licenseValid, licenseValidTill, name, userLoggedIn, databaseSynced, syncedAt, timestamp}  = JSON.parse(localdata);
        obj.active = payload?.active  ? payload.active : active;
        obj.email = payload?.email   ? payload.email : email;
        obj.firstname = payload?.firstname  ? payload.firstname : firstname;
        obj.id = payload?.id  ? payload.id : id;
        obj.licenseValid = payload?.licenseValid !=null ? payload.licenseValid : licenseValid;
        obj.lastname = payload?.lastname  ? payload.lastname : lastname;
        obj.licenseValidTill = payload?.licenseValidTill ? payload.licenseValidTill : licenseValidTill;
        obj.name = payload?.name ? payload.name : name;
        obj.userLoggedIn = payload?.userLoggedIn != null  ? payload.userLoggedIn : userLoggedIn;
        obj.databaseSynced = payload?.databaseSynced != null ? payload.databaseSynced : databaseSynced;
        obj.syncedAt = payload?.syncedAt ? new Date(`${payload.syncedAt}`).toDateString() : syncedAt;
        // obj.timestamp = payload.timestamp ? payload.timestamp : timestamp;
    }    

    localStorage.setItem('lynAnalyticsLoggedIn', JSON.stringify(obj));
}